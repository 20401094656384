import React from 'react';
import Footer from './Footer';
import Header from "./Header";

interface LayoutProps {
    children?: React.ReactNode;
}

const Layout: React.FC<LayoutProps> = ({children}) => {
    return (
        <>
            <Header></Header>
            <main id="main" className="container">
                <div className="bg-white py-10 px-8 md:px-16 lg:py-20 lg:px-32 overflow-x-hidden">{children}</div>
            </main>
            <Footer></Footer>
        </>
    );
};

export default Layout;

export const Head = () => {

}
