import * as React from "react"
import Layout from "../components/Layout";
import SEO from "../components/seo";

export default () => {

    return (<Layout>
        <div id="content" className={'animate-fade-in'}>
            <h1 className={'font-bold'}>Kontakt</h1>
            <p>
                Telefon <a href={'tel:+497307951705'}>+49 (0) 7307 9517 05</a><br/>
                Telefax <a className={'select-all'}>+49 (0) 7307 9517 03</a><br/>
                E-Mail: <a href={'mailto:post@kanzlei-reiser.de'}>post@kanzlei-reiser.de</a><br/>
                <a href={'https://www.kanzlei-reiser.de'}>www.kanzlei-reiser.de</a>
            </p>
            <h2>Rechtsanwalt Thomas Reiser</h2>
            <span>
                    Hauptstraße 43 <br/>
                    89250 Senden<br/>
                </span>
            <p>Parkmöglichkeiten direkt vor der Kanzlei</p>
            <h2>Witzighausen (Zweigstelle)</h2>
            <span>
                Weiherweg 8 <br/>
                89250 Witzighausen <br/>
                </span>
            <p>Parkmöglichkeiten direkt vor der Kanzlei</p>
        </div>
    </Layout>)
};

export const Head = () => {
    return (<SEO title={'Kontakt'}/>);
}
